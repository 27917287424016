import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const Spinner = ({ className = '', color = 'white' }) => (
  <span className={`spinner ${styles.spinner} ${className} ${styles[color]}`}>
    <span />
    <span />
    <span />
    <span />
  </span>
);

Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['white', 'turquoise']),
};

export default Spinner;
