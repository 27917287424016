import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';

import { KNOWLEDGE_HUB_URL } from '../../../utils/constants';

const rollbar = require('../../../utils/rollbar');

/**
 * A little proxy around Next.js <Link> component
 * to make sure that only the correct `props` passed to <Link> component.
 */
const CustomLink = (props) => {
  const propsWithDefaults = {
    ...defaultProps,
    ...props,
  };

  const router = useRouter();
  const query = router.query || {};

  // Check if previewMode is enabled by previewToken value.
  const previewMode = !!query.previewToken?.length;

  const linkProps = {};
  const allowedProps = [
    'href',
    'as',
    'prefetch',
    'replace',
    'shallow',
    'passHref',
    'scroll',
    'children',
    'onClick',
    'onTouchStart',
    'onMouseEnter',
    'locale',
  ];

  // When the link comes empty due to some coding mistake fail gracefully.
  if (!propsWithDefaults.href) {
    if (rollbar && !previewMode) {
      rollbar.error(
        'Empty link. A void tag will be used instead. This probably due to some coding mistake that allows undefined links to be rendered.',
      );
    }

    return <a href="#" />;
  }

  // Workaround for reactive search. The lib doesn't work with CSR.
  // We render all links for Knowledge hub project without Link component.
  // For example: /knowledge-hub?filter_regions=["Asia"]
  // It is not a Primary solution. Look at getSearchParams / setSearchParams methods
  // in <ReactiveBase />. They can potentially help integrate ReactiveSearch
  // with Next.js routing.
  if (
    propsWithDefaults.as &&
    (propsWithDefaults.as.startsWith(`${KNOWLEDGE_HUB_URL}?`) ||
      propsWithDefaults.as === KNOWLEDGE_HUB_URL) &&
    propsWithDefaults.children &&
    propsWithDefaults.children.type === 'a'
  ) {
    return <a href={propsWithDefaults.as} {...propsWithDefaults.children.props} />;
  }

  // Put into link properties only values which are allowed for <Link> component.
  Object.keys(propsWithDefaults).forEach((key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (propsWithDefaults.hasOwnProperty(key) && allowedProps.includes(key)) {
      linkProps[key] = propsWithDefaults[key];
    }
  });

  // TODO: Update all components that use Link component to avoid <a>
  // child to be able to use a modern behaviour.
  return <Link {...linkProps} legacyBehavior />;
};

const propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefetch: PropTypes.bool,
  replace: PropTypes.bool,
  shallow: PropTypes.bool,
  passHref: PropTypes.bool,
  scroll: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

const defaultProps = {
  href: null,
  as: null,
  prefetch: false,
  replace: false,
  shallow: false,
  passHref: false,
  scroll: true,
  children: null,
};

CustomLink.propTypes = propTypes;

export { propTypes, defaultProps };

export default CustomLink;
