import React from 'react';
import PropTypes from 'prop-types';

const TagText = ({
  label,
  backgroundColor = 'black',
  textColor = 'white',
  borderColor = null,
  isUppercase = false,
  title = null,
}) => (
  <mark
    className={`tag tag-text tag-text__background--${backgroundColor} tag-text__border--${borderColor}`}
  >
    <strong title={title} className={`tag-text__text--${textColor} ${isUppercase ? 'upper' : ''}`}>
      {label}
    </strong>
  </mark>
);

TagText.propTypes = {
  label: PropTypes.string.isRequired,
  isUppercase: PropTypes.bool,
  textColor: PropTypes.oneOf(['white', 'black-emergency']),
  borderColor: PropTypes.oneOf(['red', undefined]),
  backgroundColor: PropTypes.oneOf(['red', 'black', 'black-emergency', undefined]),
  title: PropTypes.string,
};

export default TagText;
