import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import NextChevron from '../../../public/static/icons/navigation-chevron-right-white-thick.svg';
import ExternalLink from '../../../public/static/icons/external-link-2.svg';

/**
 * Buttons allow users to trigger an action or event with a single click.
 * For example, you can use a button for allowing the functionality of submitting a form,
 * opening a dialog, canceling an action or redirecting users to another resource.
 */
/* eslint-disable-next-line react/prefer-stateless-function */
class Button extends React.Component {
  render() {
    const {
      tag = 'button',
      buttonType = 'submit',
      type = 'primary',
      size = 'large',
      className = '',
      isUppercase = false,
      isOutlined = false,
      isDisabled = false,
      isBlock = false,
      isLoading = false,
      isMobileBlock = false,
      isExternal = false,
      children = '',
      withArrow = false,
      withBackArrow = false,
      onClick = () => {},
      onKeyDown = null,
      ...props
    } = this.props;

    const btnProps = {};

    const classes = ['button', className];
    classes.push(`button-${type}`);
    classes.push(`button-${size}`);

    if (isOutlined) {
      classes.push('button-outline');
    }

    if (isBlock) {
      classes.push('button-block');
    }

    if (isMobileBlock) {
      classes.push('button-mobile-block');
    }

    if (isExternal) {
      classes.push('button-external');
    }

    if (withArrow) {
      classes.push('button-with-arrow');
    }

    if (withBackArrow) {
      classes.push('button-with-back-arrow');
    }

    if (isLoading) {
      classes.push('button-loading');
    }

    if (isUppercase) {
      classes.push('button-caps');
    }

    btnProps.className = classes.join(' ');

    if (tag === 'button') {
      btnProps.type = 'submit';
      if (buttonType) {
        btnProps.type = buttonType;
      }
    }

    // For better accessibility disabled buttons remain focusable. For that reason we have to
    // prevent default behaviour for aria-disabled buttons.
    function handleClick(e) {
      if (isDisabled) {
        e.preventDefault();
      } else {
        onClick(e);
      }
    }

    function handleKeyDown(e) {
      if (onKeyDown) {
        onKeyDown(e);
        return;
      }

      const enter = 13;
      if (e.keyCode === enter) {
        onClick(e);
      }
    }
    return createElement(
      tag,
      {
        // tabIndex could be overwritten by props.
        tabIndex: 0,
        ...btnProps,
        ...props,
        'aria-disabled': tag !== 'a' ? isDisabled : null,
        onClick: handleClick,
        onKeyDown: handleKeyDown,
      },
      <>
        {isLoading && <Spinner />}
        {withBackArrow && <NextChevron aria-hidden="true" />}
        <span>{children}</span>
        {withArrow && <NextChevron aria-hidden="true" />}
        {isExternal && <ExternalLink aria-label="Opens a new window" />}
      </>,
    );
  }
}

Button.propTypes = {
  /**
   * Uses `<a>` or `<button>` tags in html.
   */
  tag: PropTypes.oneOf(['button', 'a']),
  buttonType: PropTypes.oneOf(['button', 'submit', 'reset']),
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'emergency',
    'white-pink',
    'white-green',
    'quick-link',
    'toggled',
    'untoggled',
    'activism',
    'activism-yellow',
    'activism-black',
    'tangerine',
    'cobalt',
    'xmas',
    'dark',
  ]),
  size: PropTypes.oneOf(['small', 'large', 'extra-large']),
  /**
   * Adds class to a button.
   */
  className: PropTypes.string,
  /**
   * Usually it is a label for a button.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isDisabled: PropTypes.bool,
  isOutlined: PropTypes.bool,
  /**
   * Displays loading icon on a button.
   */
  isLoading: PropTypes.bool,
  /**
   * Displays as a block.
   */
  isBlock: PropTypes.bool,
  /**
   * Displays uppercase label for a button.
   */
  isUppercase: PropTypes.bool,
  /**
   * Displays as a block on mobile devices and as inline element on other devices.
   */
  isMobileBlock: PropTypes.bool,
  /**
   * Adds external icon on the button.
   */
  isExternal: PropTypes.bool,
  /**
   * Adds arrow on the button.
   */
  withArrow: PropTypes.bool,
  withBackArrow: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default Button;
