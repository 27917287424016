import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../01_atoms/Link';
import TagText from '../../../01_atoms/Tag/TagText';
import NextChevron from '../../../../public/static/icons/navigation-chevron-right-white.svg';
import ExclamationIcon from '../../../../public/static/icons/pink-exclamation-red.svg';

const AppealBanner = ({ emergencyTag = null, label = '', text = '', url = {} }) => (
  <div
    className={`top-banner top-banner-${emergencyTag ? 'emergency' : 'appeal'}`}
    data-test="top-banner-appeal"
  >
    <Link {...url.nextLink}>
      <a className={`top-banner-cta top-banner-cta-${emergencyTag ? 'emergency' : 'appeal'}`}>
        {!emergencyTag && <span className="label">{label}: </span>}

        {emergencyTag && (
          <>
            <span className="emergency-icon">
              <ExclamationIcon />
            </span>

            <div className="emergency-tag-label">
              <TagText label={emergencyTag} backgroundColor="red" borderColor="red" isUppercase />

              <TagText label={label} borderColor="red" isUppercase />
            </div>
          </>
        )}

        <span className="text">{text}</span>

        <span className="chevron">
          <NextChevron />
        </span>
      </a>
    </Link>
  </div>
);

AppealBanner.propTypes = {
  emergencyTag: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.shape(),
};

export default AppealBanner;
